/*
 * ### general color classes
 */
/* colors */
.is--c-white,
.is--c-w {
    &,
    *,
    &:after {
        color: $color--white;
    }
}

.is--c-w75 {
    color: $color--white-75;
}

.is--pri,
.is--c-pri1 {
    &,
    *,
    &:after {
        color: $color--primary;
    }
}

.is--pri2,
.is--c-pri2 {
    &,
    *,
    &:after {
        color: $color--primary-2;
    }
}

.is--c-pri2-dark {
    &,
    *,
    &:after {
        color: $color--primary-2-dark;
    }
}

.is--pri3,
.is--c-pri3 {
    &,
    *,
    &:after {
        color: $color--primary-3;
    }
}

.is--c-pri4 {
    &,
    *,
    &:after {
        color: $color--primary-4;
    }
}

.is--c-pri5 {
    &,
    *,
    &:after {
        color: $color--primary-5;
    }
}

.is--c-pri6 {
    &,
    *,
    &:after {
        color: $color--primary-6;
    }
}

.is--c-sec1 {
    &,
    &:before,
    &:after {
        color: $color--sec-1;
    }
}

.is--c-sec2 {
    &,
    &:before,
    &:after {
        color: $color--sec-2;
    }
}

.is--c-sec3 {
    &,
    &:before,
    &:after {
        color: $color--sec-3;
    }
}

.is--c-sec4 {
    &,
    &:before,
    &:after {
        color: $color--sec-4;
    }
}

.is--c-sec5 {
    &,
    &:before,
    &:after {
        color: $color--sec-5;
    }
}

.is--c-sec6 {
    &,
    &:before,
    &:after {
        color: $color--sec-6;
    }
}

.is--c-sec7 {
    &,
    &:before,
    &:after {
        color: $color--sec-7;
    }
}

.is--c-sec8 {
    &,
    &:before,
    &:after {
        color: $color--sec-8;
    }
}

.is--c-fontgrey {
    color: $color-font--grey;
}

.is--c-grey {
    &,
    *,
    &:after {
        color: $color-font--mediumgrey;
    }
}

.is--c-lightgrey {
    &,
    *,
    &:after {
        color: $color-font--lightgrey-2;
    }
}

.is--c-font {
    &,
    *,
    &:after {
        color: $color-font;
    }
}

.is--c-font-darkgrey {
    color: $color-font--darkgrey;
}

.is--c-f-bgd {
    color: $color-font--backend-grey-darker;
}

.is--ok,
.is--c-ok {
    color: $color-status--ok;
}

.is--warning,
.is--c-warning {
    color: $color-status--warning;
}

.is--error,
.is--c-error {
    color: $color-status--error;
}

.is--c-b-grey {
    color: $color-font--backend-grey;
}

/* colors hover */
.is--c-hov-pri3 {
    transition: color $transition-default;

    &:hover {
        color: $color--primary-3;
    }
}

/* background colors */
.is--bg-trans {
    background-color: transparent;
}

.is-g-bg--white,
.is--bg-w {
    background-color: $color--white;
}

.is--bg-pri1 {
    background-color: $color--primary;
}

.is--bg-pri2 {
    background-color: $color--primary-2;
}

.is--bg-pri2-dark {
    background-color: $color--primary-2-dark;
}

.is--bg-pri3 {
    background-color: $color--primary-3;
}

.is--bg-pri4 {
    background-color: $color--primary-4;
}

.is--bg-pri5 {
    background-color: $color--primary-5;
}

.is--bg-pri6 {
    background-color: $color--primary-6;
}

.is--bg-sec1 {
    background-color: $color--sec-1;
}

.is--bg-sec2 {
    background-color: $color--sec-2;
}

.is--bg-sec3 {
    background-color: $color--sec-3;
}

.is--bg-sec4 {
    background-color: $color--sec-4;
}

.is--bg-sec5 {
    background-color: $color--sec-5;
}

.is--bg-sec6 {
    background-color: $color--sec-6;
}

.is--bg-sec7 {
    background-color: $color--sec-7;
}

.is--bg-sec8 {
    background-color: $color--sec-8;
}

.is--bg-body {
    background-color: $color-bg--body;
}

.is--bg-body-grey {
    background-color: $color-bg--body-grey;
}

.is--bg-lightgrey {
    background-color: $color--lightgrey;
}

.is--bg-ok {
    background-color: $color-status--ok;
}

.is--bg-warning {
    background-color: $color-status--warning;
}

.is--bg-error {
    background-color: $color-status--error;
}

.is--bg-dark {
    background-color: $color-bg--dark;
}

.is--bg-black {
    background-color: $color--black;
}

/* background gradients */
.is--bg-gradient-2-3 {
    background-image: $color-gradient--2to3;
}

.is--bg-gradient-3-2 {
    background-image: $color-gradient--3to2;
}

/* background specials */
.is--bg-line {
    background-repeat: no-repeat;
    background-size: 100% .375rem;
}
