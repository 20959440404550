.e-checkbox {
    @include position(relative);
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: $font-lh--default;
    padding-left: 2rem;
    user-select: none;

    &:hover {
        cursor: pointer;
    }

    &.is--checked {
        input {
            ~ .e-checkbox__text {
                &:after {
                    display: block;
                }
            }
        }
    }

    &:not(.is--tile) {
        input {
            @include position(absolute, $top: 0, $left: 0);
            appearance: none;
            background-color: transparent;
            border-color: transparent;
            height: 1px;
            outline-color: transparent;
            width: 1px;

            &:checked {
                ~ .e-checkbox__text {
                    background-color: $color--primary-3;
                    border-color: $color--primary-3;

                    &:after {
                        display: block;
                    }
                }
            }
        }
    }

    &.is--tile {
        padding-left: 0;
        width: 100%;

        * {
            &:hover {
                cursor: pointer;
            }
        }

        input {
            ~ div {
                border: solid $dimensions-border--small $color--transparent;
                border-radius: $dimensions-border-radius--6;
                margin-left: $margin-small;
                padding: $margin-small;
                width: 100%;
            }

            &:checked {
                ~ div {
                    border-color: $color-font--lightgrey-2;
                    font-weight: $font-weight--medium;
                }
            }
        }
    }

    &__info {
        display: flex;

        .is--number {
            color: $color-font--lightgrey-2;
            display: block;
            font-weight: $font-weight--bold;
            padding-right: $margin-small;
        }
    }

    &__text {
        @include position(absolute, $top: 0, $left: 0);
        background-color: $color--white;
        border: solid $dimensions-border--default $color--primary;
        border-radius: .31rem;
        box-sizing: border-box;
        height: 1.25rem;
        width: 1.25rem;

        &:after {
            @include position(absolute, $top: 0, $left: .25rem);
            border: solid $color--primary;
            border-width: 0 .188rem .188rem 0;
            content: ' ';
            display: none;
            height: .625rem;
            transform: rotate(45deg);
            width: .313rem;
        }
    }

}
