/*
------------------------------------------------------
--------------------- animations
------------------------------------------------------
*/

/* classes */
/*.is--js-inview {
    opacity: 0;
}

.is--inview {
    animation: .6s $transiion-easing--default 0s 1 normal forwards running inViewFadeInUp;
    opacity: 1;
}*/

.is--inview-fade {
    animation: .3s $transiion-easing--default 0s 1 normal forwards running fadeIn;
    opacity: 1;
}

/* animations */
@keyframes gradientBGLoading {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@keyframes rotating_full {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes inViewFadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes loading {
    30% {
        opacity: 1;
    }

    85% {
        border-color: $color--white;
        opacity: 1;
        transform: rotate(1080deg);
    }

    95% {
        border-color: $color--primary-2-dark;
        opacity: 1;
        transform: rotate(1080deg);
    }

    100% {
        border-color: $color--primary-2-dark;
        opacity: 0;
        transform: rotate(1080deg);
    }
}

@keyframes logoBackend {
    from {
        fill: $color--primary-3;
    }

    to {
        fill: $color--primary;
    }
}

@keyframes loader-animation {
    0% {
        width: 0;
    }

    49% {
        left: 0;
        width: 100%;
    }

    100% {
        left: 100%;
        width: 0;
    }
}

@keyframes circle_waiting {
    0% {
        height: 0;
        left: 36px;
        opacity: 1;
        top: 36px;
        width: 0;
    }

    100% {
        height: 72px;
        left: 0;
        opacity: 0;
        top: 0;
        width: 72px;
    }
}

@keyframes rollOut {
    0% {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0) rotate(120deg);
    }
}

@keyframes bounceOutUp {
    0% {
        transform: translate3d(0, 0, 0) scaleY(1);
    }

    20% {
        transform: translate3d(0, -10px, 0) scaleY(.985);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0) scaleY(.9);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0) scaleY(3);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInDelay {
    0% {
        opacity: 0;
    }
    
    10% {
        opacity: 0;
    }
    
    100% {
        opacity: 1;
    }
}

@keyframes fadeInLB {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: $color-bg--lighbox;
    }
}
