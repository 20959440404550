/*
 * ### general font classes
 */
/* font size */
.is--flex {
    display: flex;

    &.is--c {
        justify-content: center;
    }

    &.is--r {
        justify-content: flex-end;
    }
}

.is--flex-ac {
    align-items: center;
}

.is--flex-v-stretch {
    align-items: stretch;
}
