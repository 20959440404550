.e-loader {
    align-items: center;
    background: transparent;
    border: solid 2px $color--white;
    border-radius: 50%;
    border-top-color: $color--primary-2-dark;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 30px auto 0;
    opacity: 0;
    width: 50px;

    &__outer {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &.is--active {
        &:not(.is--checked) {
            animation: loading 2s ease-in-out infinite;
            animation-fill-mode: forwards;
        }

        &.is--checked {
            border-color: $color--primary-2-dark;
            opacity: 1;
        }
    }

    &__check {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        opacity: 0;
        transform: translate3d(-4px, 50px, 0);
        width: 100%;

        &.is--checked {
            opacity: 1;
            transform: translate3d(-4px, 4px, 0);
            transition: opacity .5s cubic-bezier(.49, 1.74, .38, 1.74), transform .5s cubic-bezier(.49, 1.74, .38, 1.74);
            transition-delay: .2s;

            span {
                opacity: 1;
            }
        }

        span {
            background-color: $color--primary-2-dark;
            display: block;
            height: 2px;

            &:nth-child(1) {
                transform: rotate(45deg);
                width: 10px;
            }

            &:nth-child(2) {
                transform: rotate(-45deg) translate3d(14px, -4px, 0);
                transform-origin: 100%;
                width: 20px;
            }
        }
    }
}
