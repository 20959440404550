/* margin top */
.is--mt-small {
    margin-top: $margin-small;
}

.is--mt-input {
    margin-top: $margin-input;
}

.is--mt-smaller {
    margin-top: $margin-smaller;
}

.is--mt-xsmaller {
    margin-top: $margin-xsmaller;
}

.is--mt-default {
    margin-top: $margin-default;
}

.is--mt-xdefault {
    margin-top: $margin-xdefault;
}

.is--mt-bigger {
    margin-top: $margin-bigger;
}

.is--mt-big {
    margin-top: $margin-big;
}

.is--mt-huge {
    margin-top: $margin-huge;
}
