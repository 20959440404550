.e-img {
    &__person {
        border-radius: 50%;
        height: 5rem;
        overflow: hidden;
        width: 5rem;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}
