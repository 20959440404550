/* padding bottom */
.is--pb-small,
.is--padb-small {
    padding-bottom: $margin-small;
}

.is--pb-input {
    padding-bottom: $margin-input;
}

.is--pb-smaller {
    padding-bottom: $margin-smaller;
}

.is--pb-xsmaller {
    padding-bottom: $margin-xsmaller;
}

.is--padb,
.is--pb,
.is--pb-d,
.is--pb-default {
    padding-bottom: $margin-default;
}

.is--pb-xdefault {
    padding-bottom: $margin-xdefault;
}

.is--pb-bigger {
    padding-bottom: $margin-bigger;
}

.is--pb-big {
    padding-bottom: $margin-big;
}

.is--padb-huge,
.is--pb-h {
    padding-bottom: $margin-huge;
}

.is--p-b-default-small,
.is--pb-d-small {
    @include media('<=small') {
        padding-bottom: $margin-default;
    }
}
