/* padding left */
.is--pl-small {
    padding-left: $margin-small;
}

.is--pl-input {
    padding-left: $margin-input;
}

.is--pl-smaller {
    padding-left: $margin-smaller;
}

.is--pl-xsmaller {
    padding-left: $margin-xsmaller;
}

.is--padl,
.is--pl,
.is--pl-default {
    padding-left: $margin-default;
}

.is--pl-xdefault {
    padding-left: $margin-xdefault;
}

.is--pl-bigger {
    padding-left: $margin-bigger;
}

.is--pl-big {
    padding-left: $margin-big;
}

.is--pl-huge {
    padding-left: $margin-huge;
}
