.e-def {
    font-size: $font-size--xsmall;
    padding-bottom: $margin-small;

    :first-child {
        color: $color-font--backend-grey-darker;

        &:not(.e-def__content) {
            font-weight: $font-weight--medium;
            padding-right: $margin-small;
        }
    }

    :nth-child(2) {
        color: $color-font--backend-grey-darker;
    }

    &.is--no-line {
        display: flex;
        flex-direction: column;

        :first-child {
            padding-bottom: $margin-small;
        }
    }
}
