/* margin right */
.is--mr-small {
    margin-right: $margin-small;
}

.is--mr-input {
    margin-right: $margin-input;
}

.is--mr-smaller {
    margin-right: $margin-smaller;
}

.is--mr-xsmaller {
    margin-right: $margin-xsmaller;
}

.is--mr-default {
    margin-right: $margin-default;
}

.is--mr-xdefault {
    margin-right: $margin-xdefault;
}

.is--mr-bigger {
    margin-right: $margin-bigger;
}

.is--mr-big {
    margin-right: $margin-big;
}

.is--mr-huge {
    margin-right: $margin-huge;
}
