/*
 * ### global dimensions config
 */

/* font */
$dimensions-font--bigger: 1.5rem;
$dimensions-font--big: 2rem;
$dimensions-font--bigx: 4rem;

/* max-width */
$dimensions-max-width-40: 40rem;
