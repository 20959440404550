// sass-lint:disable-all
// ==========================================================================
// Mixin :: iconfont

@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }
    &:#{$position} {
        @if $icon {
            content: "#{map-get($icons, $icon)}";
        }
        @if $styles {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            display: inline-block;
            font-family: $font-family--icons;
            font-style: normal;
            font-size: $font-size--default;
            font-variant: normal;
            font-weight: normal;
            position: relative;
            line-height: 1;
            text-align: center;
            text-decoration: none;
            text-transform: none;
            speak: none;
            width: auto;
        }
        @content;
    }
}
// sass-lint:enable-all
