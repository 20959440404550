.e-table {
    width: 100%;

    th {
        text-align: left;
    }

    th,
    td {
        padding: $margin-smaller;
    }

    tr {
        &:not(last-of-type) {
            border-bottom: solid $dimensions-border--small $color-border--grey;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: $color-bg--body-grey;
            }
        }
    }
}

// sass-lint:disable-all
.dataTables_paginate {
    display: flex;

    span {
        display: flex;
    }
}

.paginate_button {
    align-items: center;
    background-color: $color-bg--body;
    border: solid $dimensions-border--small $color-border--grey-backend;
    border-radius: 0;
    display: flex;
    justify-content: center;
    padding: $margin-small;
    transition: background-color $transition-default;

    &:hover {
        &:not(.current) {
            background-color: darken($color-bg--body, 5%);
            cursor: pointer;
        }
    }

    &.current {
        background-color: $color-font--lightgrey-2;
        color: $color--white;
    }
}

.dataTables_length,
.dataTables_filter {
    margin-bottom: $margin-small;

    label {
        font-size: $font-size--xsmall;
    }
}

.dataTables_info,
.paginate_button {
    font-size: $font-size--xsmall;
}

table {
    &.dataTable {
        &.no-footer {
            border-bottom: 0;
        }
    }
}
// sass-lint:enable-all
