// sass-lint:disable-all
.c-popup {
    display: none;

    &.c-popup--visible {
        display: block;
    }

    &__close {
        @include icon(after, 'cancel');
        @include position(absolute, $top: calc($margin-default / 2), $right: $margin-default);
        align-items: center;
        border: 0;
        border-radius: $dimensions-border-radius--3;
        cursor: pointer;
        display: flex;
        height: 2rem;
        justify-content: center;
        margin: 0;
        outline: none;
        padding: 0;
        width: 2rem;
        z-index: 10;

        &:hover {
            background-color: $color--primary-3;
            color: $color--white;
        }
    }

    &__box {
        @include position(fixed, $left: $margin-default, $bottom: $margin-default);
        background: $color--white;
        border-radius: $dimensions-border-radius--3;
        box-shadow: $shadow--tile;
        overflow: hidden;
        width: 25rem;
        z-index: 1001;

        &__footer {
            overflow: hidden;
            padding: 40px 20px 20px;

            &__right-space {
                float: right;

                .c-popup__btn {
                    margin-left: 5px;
                }
            }

            &__left-space {
                float: left;

                .c-popup__btn {
                    margin-right: 5px;
                }
            }
        }

        &__body {
            @include position(relative);
            background: $color--white;
            color: #454B4D;
            padding: 20px;
            z-index: 2;

            p {
                margin: 0 0 5px;
            }
        }

        &__header {
            background: $color--white;
            border-bottom: solid $dimensions-border--small $color--lightgrey;
            color: #454B4D;
            padding: 15px 20px;

            &__title {
                font-size: $font-size--xdefault;
                margin: 0;
                text-align: left;
            }
        }
    }
}
// sass-lint:enable-all
