/*
 * ### global border config
 */

/* border radius */
.is--br-tile {
    border-radius: $border-radius-tile;
}

/* border */
.is--br-none {
    border: 0;
}

.is--brt {
    border-top: solid $dimensions-border--small $color-border--grey;
}
