/* margin bottom */
.is--mb-small {
    margin-bottom: $margin-small;
}

.is--mb-input {
    margin-bottom: $margin-input;
}

.is--mb-smaller {
    margin-bottom: $margin-smaller;
}

.is--mb-xsmaller {
    margin-bottom: $margin-xsmaller;
}

.is--mb-default {
    margin-bottom: $margin-default;
}

.is--mb-xdefault {
    margin-bottom: $margin-xdefault;
}

.is--mb-bigger {
    margin-bottom: $margin-bigger;
}

.is--mb-big {
    margin-bottom: $margin-big;
}

.is--mb-huge {
    margin-bottom: $margin-huge;
}
