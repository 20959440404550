.e-link {
    color: $color--primary-3;
    font-weight: $font-weight--bold;

    &:before,
    &:after {
        transition: transform $transition-default;
    }

    &[class*='is--ico-'] {
        &:before {
            padding-right: $margin-small;
        }

        &:hover {
            &:before {
                transform: translateX(-.1rem);
            }
        }
    }

    &[class*='is--ico-after'] {
        &:after {
            padding-left: $margin-small;
        }

        &:hover {
            &:after {
                transform: translateX(.1rem);
            }
        }
    }

    &.is--grey {
        color: $color-font--darkgrey;
    }

    &.is--normal {
        color: $color-font;
        font-weight: $font-weight--default;

        &:hover {
            text-decoration: underline;
        }
    }

    &.is--normal-underline {
        color: $color-font;
        font-weight: $font-weight--default;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .is--fs-h1 & {
        color: $color--primary-2-dark;
        font-weight: inherit;
        line-height: inherit;

        &:after {
            font-size: $dimensions-headline--h1;
            font-weight: $font-weight--boldest;
        }
    }

    .is--fs-h2 & {
        color: $color--primary-2-dark;
        font-weight: inherit;
        line-height: inherit;

        &:after {
            font-size: $dimensions-headline--h2;
            font-weight: $font-weight--boldest;
        }
    }
}
