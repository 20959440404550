/*
 * ### global transition config
 */

/* duration */
$transition-small: .1s;
$transition-duration: .3s;
$transition-duration--slower: .6s;
$transition-duration--slow: 1s;

/* easing */
$transiion-easing--default: cubic-bezier(.77, 0, .175, 1);
$transiion-easing--easeinoutcirc: cubic-bezier(.785, .135, .15, .86);

/* default */
$transition-small: $transition-small $transiion-easing--default;
$transition-default: $transition-duration $transiion-easing--default;
$transition-fast: $transition-duration ease-in-out;
$transition-default-after-default: $transition-duration $transition-duration $transiion-easing--default;
$transition-easeinoutcirc: $transition-duration $transiion-easing--easeinoutcirc;
$transition-easeinoutcirc--slower: $transition-duration--slower $transiion-easing--easeinoutcirc;
