.c-tiles {
    &__grid12 {
        display: grid;
        // sass-lint:disable-all
        gap: $margin-default;
        // sass-lint:enable-all
        grid-template-columns: repeat(12, 1fr);

        &.is--grid-a-s {
            align-items: flex-start;
        }

        > div {
            border-radius: $dimensions-border-radius--6;
            padding: $margin-smaller;

            &.is--grid-2 {
                grid-column: span 2;
            }

            &.is--grid-3 {
                grid-column: span 3;
            }

            &.is--grid-4 {
                grid-column: span 4;
            }

            &.is--grid-5 {
                grid-column: span 5;
            }

            &.is--grid-6 {
                grid-column: span 6;
            }

            &.is--grid-7 {
                grid-column: span 7;
            }

            &.is--grid-8 {
                grid-column: span 8;
            }

            &.is--grid-9 {
                grid-column: span 9;
            }

            &.is--grid-10 {
                grid-column: span 10;
            }

            &.is--grid-11 {
                grid-column: span 11;
            }

            &.is--grid-12 {
                grid-column: span 12;
            }
        }
    }
}
