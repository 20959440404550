/*
 * ### global color config
 */

/* primary */
$color--primary: #101a35;
$color--primary-light: lighten($color--primary, 5%);
$color--primary-lighter: lighten($color--primary, 70%);
$color--primary-dark: darken($color--primary, 5%);
$color--primary-highlighted: #102039;
$color--primary-2: #9e6bf8;
$color--primary-2-dark: darken($color--primary-2, 15%);
$color--primary-2-light: lighten($color--primary-2, 20%);
$color--primary-3: #0ac7ab;
$color--primary-3-lightdarker: darken($color--primary-3, 2%);
$color--primary-3-darker: darken($color--primary-3, 10%);
$color--primary-3-lighter: lighten($color--primary-3, 35%);
$color--primary-3-light: lighten($color--primary-3, 55%);
$color--primary-4: #d5f5f0;
$color--primary-5: #e6eaf7;
$color--primary-5-darker: darken($color--primary-5, 30%);
$color--primary-6: #f59200;
$color--primary-6-lighter: lighten($color--primary-6, 35%);
$color--primary-6-darker: darken($color--primary-6, 10%);
$color--lightgrey: #f6f6f8;

/* secondary */
$color--sec-1: #2714de;
$color--sec-2: #173efa;
$color--sec-3: #9514de;
$color--sec-4: #e617fa;
$color--sec-5: #581bc2;
$color--sec-6: #9f6cf7;
$color--sec-7: #f59f3b;
$color--sec-8: #0fa82d;

/* admin */
$color--white: #fff;
$color--white-75: rgba(255, 255, 255, .75);
$color--black: #000;
$color--transparent: rgba(0, 0, 0, 0);
$color--transparent-1: rgba(0, 0, 0, .1);
$color--w-trans-8: rgba(256, 256, 256, .8);

/* backgrounds (bg) */
$color-bg--body: #f5f7fa;
$color-bg--body-grey: #f2f3f5;
$color-bg--body-db: $color--lightgrey;
$color-bg--grey: #e5e9f6;
$color-bg--lighbox: rgba($color--primary, .2);
$color-bg--dark: #0d0d0d;

/* border */
$color-border--grey: #f0f1f5;
$color-border--gray-darker: #d0d0d0;
$color-border--grey-backend: #ced4da;

/* font */
$color-font: $color--primary-dark;
$color-font--darkgrey: #717171;
$color-font--mediumgrey: #949494;
$color-font--lightgrey: #cbccce;
$color-font--lightgrey-2: lighten($color-font--mediumgrey, 20%);
$color-font--button-backend: #42526e;
$color-font--backend-grey: #82879f;
$color-font--backend-grey-darker: rgb(66, 82, 110);
$color-font--grey: #c1c8d8;
$color-font--darkgrey: #434863;

/* form */
$color-form--lightgrey: darken($color--lightgrey, 5%);
$color-form--grey: darken($color-font--lightgrey, 10%);
$color-form--input: #cfd0d5;

/* status */
$color-status--ok: #00de7e;
$color-status--warning: #ff830d;
$color-status--error: #ff755f;
$color-status--star: #ffcd54;

/* gradient */
$color-gradient--2to3: linear-gradient(to right, $color--primary-2, $color--primary-3);
$color-gradient--3to2: linear-gradient(to right, $color--primary-3, $color--primary-2);
$color-gradient--pri2dark-to-orange: linear-gradient(to top, $color--primary-2-dark, $color-status--warning);
$color-gradient--pri2dark-to-orange-90: linear-gradient(90deg, $color--primary-2-dark, $color-status--warning);
$color-gradient--1-lighttodark: linear-gradient(to right, $color--primary-light, $color--primary-dark);
$color-gradient--transtowhite: linear-gradient($color--transparent, $color--white);

/* transparent */
$color-transparent--dark: rgba($color--primary, .9);
$color-transparent--white: rgba($color--white, .9);
$color-transparent--lightbox: rgba(0, 0, 0, .3);

/* special */
$color-special-author: rgba($color--primary-2, .8);
