/* margin top-bottom */
.is--mtb-small {
    margin: $margin-small 0;
}

.is--mtb-input {
    margin: $margin-input 0;
}

.is--mtb-smaller {
    margin: $margin-smaller 0;
}

.is--mtb-xsmaller {
    margin: $margin-xsmaller 0;
}

.is--mtb-default {
    margin: $margin-default 0;
}

.is--mtb-xdefault {
    margin: $margin-xdefault 0;
}

.is--mtb-bigger {
    margin: $margin-bigger 0;
}

.is--mtb-big {
    margin: $margin-big 0;
}

.is--mtb-huge {
    margin: $margin-huge 0;
}
