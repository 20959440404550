/*
 * ### global font config
 */

/* family */
$font-family--default: 'Roboto', sans-serif;
$font-family--arial: 'Arial', sans-serif;
$font-family--icons: 'fontello';

/* size */
$font-size--smallest: .563rem; // 9px
$font-size--small: .625rem; // 10px
$font-size--xsmall: .75rem; // 12px
$font-size--default: .875rem; // 14px
$font-size--xdefault: 1rem; // 16px
$font-size--bigger: 1.125rem; // 18px
$font-size--xsbigger: 1.25rem; // 20px
$font-size--xxsbigger: 1.375rem; // 22px
$font-size--xbigger: 1.5rem; // 24px
$dimensions-headline--h1: 1.75rem; // 28px
$font-size--huge-mobile: 2rem; // 32px
$font-size--huge: 2.25rem; // 36px
$font-size--hugex-mobile: 2.5rem; //40px
$font-size--hugex: 3rem; //48px

/* weight */
$font-weight--lighter: 200;
$font-weight--light: 300;
$font-weight--default: 400;
$font-weight--medium: 500;
$font-weight--bold: 700;
$font-weight--boldest: 900;

/* line-height (lh) */
$font-lh--1: 1;
$font-lh--headline: 1.2;
$font-lh--default: 1.6;
$font-lh--bigger: 2;

/* iconfont */
$font-iconfont--code: '42712900';
