.c-status {
    align-items: center;
    display: flex;
    margin-right: $margin-small;

    &:before {
        background-color: $color-status--ok;
        border-radius: .25rem;
        content: '';
        display: block;
        height: .5rem;
        width: .5rem;
    }

    &.is--not-published {
        &:before {
            background-color: $color-status--warning;
        }
    }

    &.is--inactive {
        &:before {
            background-color: $color-font--backend-grey;
        }
    }
}
