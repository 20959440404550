.c-box {
    &.is--cc {
        @include scrollbar;
        -webkit-overflow-scrolling: touch;
        display: flex;
        height: 100%;
        justify-content: center;
        overflow-y: scroll;
    }
}
