// sass-lint:disable-all
// ==========================================================================
// Mixin :: Scrollbar

@mixin scrollbar() {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: light;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $color--white;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-font--lightgrey;

        &:hover {
            background: $color-font--lightgrey;
        }
    }
}

@mixin scrollbar_lightbox() {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: light;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        border-radius: 3.5px;
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: $color-font--lightgrey;
        border-radius: 3.5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-font--button-backend;
        border-radius: 3.5px;

        &:hover {
            background: $color-font--button-backend;
        }
    }
}

@mixin scrollbar_main() {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: light;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: $body-scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        background: $color--white;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-font--lightgrey;

        &:hover {
            background: $color-font--lightgrey;
        }
    }
}
// sass-lint:enable-all
