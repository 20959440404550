.c-optionlist {
    @include position(absolute, $top: 100%, $right: 0);
    background-color: $color--white;
    box-shadow: $shadow--light;
    display: block;
    min-width: 12rem;
    z-index: 20;

    li {
        padding: $margin-smaller;
        transition: background-color $transition-default;

        &:first-of-type {
            border-top-left-radius: $dimensions-border-radius--3;
            border-top-right-radius: $dimensions-border-radius--3;
        }

        &:last-of-type {
            border-bottom-left-radius: $dimensions-border-radius--3;
            border-bottom-right-radius: $dimensions-border-radius--3;
        }

        &:not(:last-of-type) {
            border-bottom: solid $dimensions-border--small $color-border--grey;
        }

        &:hover {
            background-color: $color--lightgrey;
            cursor: pointer;
        }
    }

    &__holder {
        @include position(relative);
    }
}
