.c-c {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: $color--white;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-bg--body-grey;

        &:hover {
            background: $color-bg--body-grey;
        }
    }

    &__content {
        @include scrollbar;

        &.is--fullwidth {
            height: calc(100% - 4rem);
        }

        &.is--scroll {
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }
    }

    &__cols {
        display: flex;

        .is--fullwidth & {
            height: 100%;
        }

        > div {
            flex-grow: 1;

            &:not(:first-of-type) {
                padding-left: $margin-default;
            }
        }
    }

    &__head {
        align-items: center;
        display: flex;
        height: 4rem;
        justify-content: space-between;
    }
}
