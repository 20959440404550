/* margin left */
.is--ml-small {
    margin-left: $margin-small;
}

.is--ml-input {
    margin-left: $margin-input;
}

.is--ml-smaller {
    margin-left: $margin-smaller;
}

.is--ml-xsmaller {
    margin-left: $margin-xsmaller;
}

.is--ml-default {
    margin-left: $margin-default;
}

.is--ml-xdefault {
    margin-left: $margin-xdefault;
}

.is--ml-bigger {
    margin-left: $margin-bigger;
}

.is--ml-big {
    margin-left: $margin-big;
}

.is--ml-huge {
    margin-left: $margin-huge;
}
