/* padding right */
.is--pr-small {
    padding-right: $margin-small;
}

.is--pr-input {
    padding-right: $margin-input;
}

.is--pr-smaller {
    padding-right: $margin-smaller;
}

.is--pr-xsmaller {
    padding-right: $margin-xsmaller;
}

.is--pr-default {
    padding-right: $margin-default;
}

.is--pr-xdefault {
    padding-right: $margin-xdefault;
}

.is--pr-bigger {
    padding-right: $margin-bigger;
}

.is--pr-big {
    padding-right: $margin-big;
}

.is--pr-huge {
    padding-right: $margin-huge;
}
