.c-lightbox {
    @include position(fixed, $top: 0, $left: 0);
    align-items: center;
    animation: fadeInLB $transition-default;
    backdrop-filter: blur(6px);
    background-color: $color-bg--lighbox;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 1000;

    .js-lightbox-con {
        display: flex;
        width: 100%;
    }

    &__outer {
        &:not(.is--lightbox-active) {
            display: none;
        }
    }

    &__content {
        @include scrollbar_lightbox;
        overflow-x: hidden;
        overflow-y: scroll;

        > div {
            background-color: $color--w-trans-8;
            margin-right: $margin-default;
        }
    }

    &__inner {
        @include position(relative);
        height: 66.6666%;
        margin: 0 auto;
        max-width: 80%;

        .is--fullwidth-lb & {
            width: 100%;
        }
    }

    &__actions {
        padding-top: $margin-default;

        ul {
            display: flex;

            li {
                &:not(:last-of-type) {
                    padding-right: $margin-small;
                }
            }
        }
    }

    &__holder {
        height: 100%;
    }

    &__header {
        @include position(absolute, $top: 0, $left: 0);
        transition: box-shadow $transition-duration $transiion-easing--default;
        width: calc(100% - #{$margin-default} - 7px);
        z-index: $zindex-absolute;

        &.is--scroll {
            backdrop-filter: blur(2px);
            background-color: $color--w-trans-8;
            box-shadow: $shadow--search;
        }
    }
}
