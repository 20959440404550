/* padding top-bottom */
.is--ptb-small {
    padding-bottom: $margin-small;
    padding-top: $margin-small;
}

.is--ptb-input {
    padding-bottom: $margin-input;
    padding-top: $margin-input;
}

.is--ptb-smaller {
    padding-bottom: $margin-smaller;
    padding-top: $margin-smaller;
}

.is--ptb-xsmaller {
    padding-bottom: $margin-xsmaller;
    padding-top: $margin-xsmaller;
}

.is--p-tb,
.is--ptb,
.is--ptb-d,
.is--ptb-default {
    padding-bottom: $margin-default;
    padding-top: $margin-default;
}

.is--ptb-xdefault {
    padding-bottom: $margin-xdefault;
    padding-top: $margin-xdefault;
}

.is--ptb-bigger {
    padding-bottom: $margin-bigger;
    padding-top: $margin-bigger;
}

.is--p-tb-b,
.is--ptb-b,
.is--ptb-big {
    padding-bottom: $margin-big;
    padding-top: $margin-big;
}

.is--ptb-h,
.is--ptb-huge {
    padding-bottom: $margin-huge;
    padding-top: $margin-huge;
}
