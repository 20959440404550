/*
 * ### general align classes
 */
.is--c {
    text-align: center;
}

.is--c-margin {
    margin: 0 auto;
}

.is--justify {
    text-align: justify;
}

.is--c-xmedium {
    @include media('<=xmedium') {
        text-align: center;
    }
}

.is--l {
    text-align: left;
}

.is--r,
.is--right {
    text-align: right;
}

.is--flex-c {
    justify-content: center;
}

.is--flex-r {
    justify-content: flex-end;
}

.is--flex-sb {
    justify-content: space-between;
}
