/*
 * ### global shadow config
 */

/* shadow color */
$shadow-color--db: rgba($color--primary, .05);
$shadow-color--db-lighter: rgba($color--primary, .01);

/* shadow */
$shadow--tiles: 0 0 5rem 0 $shadow-color--db-lighter;
$shadow--list-tiles: 0 0 2rem 0 rgba($color--primary, .1);
$shadow--list-tiles-hover: 0 .25rem 2rem 0 rgba($color--primary, .2);
$shadow--list-tiles-hover-noshadow: 0 30px 90px -30px rgba(31, 36, 48, .2);
$shadow--navigation: 0 0 .375rem lighten($color-font--mediumgrey, 20%);
$shadow--dropdown: 0 0 1rem 0 lighten($color-form--grey, 20%);
$shadow--primary: 0 0 1rem 0 rgba($color--primary, .3);
$shadow--search: 0 2rem 5.625rem rgba(31, 36, 48, .1);
$shadow--tile: 0 1rem 2rem rgba(0, 0, 0, .22);
$shadow--tile-small: 0 0 .25rem rgba(31, 36, 48, .1);
$shadow--light: 0 0 1rem lighten($color-form--grey, 20%);
$shadow--light-black: 0 0 1rem rgba($color--black, .3);
$shadow--switch: rgba(10, 10, 10, .29);
$shadow--list: 0 5px 9px rgba(0, 0, 0, .12);
$shadow--pricing: 0 .5rem 2rem rgba(0, 0, 0, .1);

/* shadow button */
$shadow--button: 0 4px 11px 0 rgba(37, 44, 97, .15), 0 2px 3px 0 rgba(93, 100, 148, .2);
$shadow--button-hover: 0 8px 11px 0 rgba(37, 44, 97, .15), 0 4px 6px 0 rgba(93, 100, 148, .2);
$shadow--button-action: 0 0 0 1px rgba(0, 0, 0, .05);

/* shadow backend */
$shadow-backend--tiles: 0 0 2.5rem rgba(0, 0, 0, .06);
$shadow-backend--list-hover: 0 .75rem 2rem 0 rgba($color--primary, .05);
