/* margin */
.is--m-none {
    margin: 0;
}

.is--m-small {
    margin: $margin-small;
}

.is--m-input {
    margin: $margin-input;
}

.is--m-smaller {
    margin: $margin-smaller;
}

.is--m-xsmaller {
    margin: $margin-xsmaller;
}

.is--m-default {
    margin: $margin-default;
}

.is--m-xdefault {
    margin: $margin-xdefault;
}

.is--m-bigger {
    margin: $margin-bigger;
}

.is--m-big {
    margin: $margin-big;
}

.is--m-huge {
    margin: $margin-huge;
}
