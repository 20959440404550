/*
 * ### global headline config (admin)
 */

/* headlines */
// screen
$dimensions-headline--h1: 2.5rem;
$dimensions-headline--h2: 2rem;
$dimensions-headline--h3: 1.375rem;
$dimensions-headline--h4: 1.125rem;

// wide
$dimensions-headline--h1-wide: 2.5rem;

//mobile
$dimensions-headline-mobile--h1: $dimensions-headline--h2;
$dimensions-headline-mobile--h2: 1.75rem;
$dimensions-headline-mobile--h3: 1.375rem;
$dimensions-headline-mobile--h4: 1.125rem;
