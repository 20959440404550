/* padding */
.is--p-none,
.is--p-n {
    padding: 0;
}

.is--p-small {
    padding: $margin-small;
}

.is--p-input {
    padding: $margin-input;
}

.is--p-smaller {
    padding: $margin-smaller;
}

.is--p-xsmaller {
    padding: $margin-xsmaller;
}

.is--p-d,
.is--p-default {
    padding: $margin-default;
}

.is--p-xdefault {
    padding: $margin-xdefault;
}

.is--p-bigger {
    padding: $margin-bigger;
}

.is--p-big {
    padding: $margin-big;
}

.is--p-huge {
    padding: $margin-huge !important;
}
