.e-text {
    &__tag {
        background-color: $color--white;
        border: solid $dimensions-border--small $color-border--grey;
        border-radius: $dimensions-border-radius--3;
        padding: 0 $margin-small;

        &.is--filled {
            background-color: $color-bg--body;
            border-radius: .75rem;
            display: inline-block;
            font-size: $font-size--xsmall;
            height: 1.5rem;
            line-height: 1.5rem;
        }
    }
}
