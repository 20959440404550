.e-actions {
    &__menu {
        display: flex;

        li {
            &:not(:last-of-type) {
                .e-btn__action {
                    border-bottom-right-radius: 0;
                    border-right-width: 0;
                    border-top-right-radius: 0;
                }
            }

            &:not(:first-of-type) {
                .e-btn__action {
                    border-bottom-left-radius: 0;
                    border-left-width: 0;
                    border-top-left-radius: 0;
                }
            }
        }
    }
}
