/*
 * ### global border config
 */

/* border */
$dimensions-border--small: .063rem;
$dimensions-border--default: .125rem;
$dimensions-border--big: .25rem;

/* radius */
$dimensions-border-radius--3: .188rem;
$dimensions-border-radius--4: .25rem;
$dimensions-border-radius--5: .313rem;
$dimensions-border-radius--6: .375rem;
$border-radius-tile: 2rem;
$diemnsions-border-radius--backend: 2.5rem;
