.e-form {
    &.is--maxwidth-350 {
        max-width: 21.875rem;
    }

    &.is--maxwidth-600 {
        max-width: 37.5rem;
    }

    &__example {
        font-size: $font-size--xsmall;
        padding: $margin-small 0;
    }

    &__wrap {
        @include position(relative);
        flex-grow: 1;
        padding-bottom: $margin-bigger;
        width: 100%;

        &:not(:last-of-type) {
            padding-right: $margin-default;
        }

        &.is--maxwidth-350 {
            max-width: 21.875rem;
        }

        &.is--maxwidth-600 {
            max-width: 37.5rem;
        }
    }

    &__grouping {
        border: solid $dimensions-border--small $color--lightgrey;
        border-radius: $dimensions-border-radius--3;
        padding: $margin-default;
        width: 100%;

        &-title {
            color: $color-font--backend-grey-darker;
            font-size: $font-size--default;
            font-weight: $font-weight--medium;
            padding-bottom: $margin-smaller;
        }
    }

    &__group {
        padding-bottom: $margin-bigger;
    }

    &__error {
        @include position(absolute, $bottom: 100%, $left: 0);
        background-color: $color-status--warning;
        display: flex;
        margin-bottom: $margin-small;
        padding: $margin-smaller;
        width: 100%;

        &:after {
            @include position(absolute, $top: 100%, $left: 50%);
            border: solid .5rem transparent;
            border-top-color: $color-status--warning;
            content: ' ';
            display: block;
            transform: translate(-50%);
        }

        &:empty {
            display: none;
        }

        &-line {
            &:not(.is--big) {
                border: solid $dimensions-border--small $color-status--ok;
                border-radius: calc($margin-input / 2);
                content: ' ';
                display: block;
                height: $margin-input;
                margin-left: $margin-small;
                margin-right: $margin-small;
                width: $margin-input;

                &.is--error {
                    border-color: $color-status--warning;
                    border-width: calc($margin-input / 2);
                }
            }
        }
    }

    &__status {
        background-color: $color-status--warning;
        margin: $margin-smaller 0;
        padding: $margin-smaller;

        p,
        span {
            color: $color--white;
        }
    }

    &__input {
        border: solid $dimensions-border--small transparent;
        border-radius: $dimensions-border-radius--3;
        display: block;
        font-size: $font-size--xsmall;
        padding: $margin-input $margin-small $margin-input 0;
        transition: background-color $transition-default, border-color $transition-default, padding-left $transition-default;
        width: 100%;

        &:focus {
            border-color: $color-border--grey-backend;
            padding-left: $margin-small;
        }

        &:hover {
            padding-left: $margin-small;

            &:not(:focus) {
                background-color: $color-bg--body;
            }
        }

        &::placeholder {
            font-size: $font-size--xsmall;
        }

        &:-webkit-autofill {
            &,
            &:hover,
            &:focus {
                box-shadow: 0 0 0 100rem $color--white inset;
            }
        }

        &.is--area {
            min-height: 10rem;
        }

        &.is--visible {
            margin: 0;
            padding-left: $margin-small;

            &:not(:focus) {
                background-color: $color--white;
                border-color: $color-border--grey-backend;
            }

            &:focus {
                background-color: $color-bg--body;
            }

            &:hover {
                background-color: darken($color-bg--body, 2%);
            }
        }

        &.is--visible-border {
            border-color: $color-border--grey-backend;
            padding-left: $margin-small;
        }

        &.is--error,
        &.is--ok {
            color: $color-font;
            font-weight: $font-weight--default;
        }

        &.is--big {
            border: solid $dimensions-border--small $color-border--gray-darker;
            border-radius: 1.5rem;
            height: 3rem;
            padding-left: 1.5rem;

            &.is--error,
            &.is--ok {
                color: $color-font;
            }

            &.is--error {
                border-color: $color-status--warning;
            }

            &.is--ok {
                border-color: $color-status--ok;
            }
        }

        &.is--multiple {
            height: 10rem;
        }

        &[data-target='jsoneditor'] {
            border: 0;
            height: 1px;
            margin: 0;
            outline: 0;
            padding: 0;
            width: 1px;
        }
    }

    &__label {
        align-items: center;
        color: $color-font--backend-grey-darker;
        display: flex;
        font-size: $font-size--xsmall;
        font-weight: $font-weight--medium;
        padding-bottom: $margin-small;

        .e-form__label-bar & {
            width: 100%;
        }

        &-bar {
            align-items: flex-start;
            display: flex;
        }
    }

    &__row {
        display: flex;
        max-width: 45rem;
    }

    &__fileupload {
        &:hover {
            cursor: pointer;
        }

        &-images {
            display: flex;
            flex-wrap: wrap;

            li {
                @include position(relative);
                background-color: $color--primary;

                &:hover {
                    @include icon(after, 'trash');
                    cursor: pointer;

                    &:after {
                        @include position(absolute, $top: 50%, $left: 50%);
                        align-items: center;
                        background-color: $color--white;
                        border-radius: 50%;
                        color: $color-status--error;
                        display: flex;
                        font-size: $font-size--xbigger;
                        height: 2.5rem;
                        justify-content: center;
                        transform: translate(-50%, -50%);
                        width: 2.5rem;
                        z-index: 10;
                    }

                    img {
                        opacity: .5;
                    }
                }
            }
        }

        &-image {
            height: 6rem;
            max-width: 100%;
            object-fit: cover;
            width: auto;
        }
    }
}
