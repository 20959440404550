.e-list {
    li {
        &:not(:last-of-type) {
            padding-bottom: $margin-small;
        }
    }

    &__cat {
        margin: 0;
        padding: 0;

        li {
            margin: inherit;
            padding: calc($margin-small / 2) 0;
        }

        ul {
            border-left: solid $dimensions-border--small $color--primary;
            margin-left: $margin-smaller;
            padding-left: $margin-small;
        }

        &-content {
            display: inline-block;
            padding-left: $margin-smaller;
        }

        &-actions {
            margin: $margin-small 0;
        }
    }

    &__icon {
        align-items: center;
        background-color: $color--primary-2-dark;
        border-radius: .75rem;
        display: flex;
        height: 1.5rem;
        justify-content: center;
        margin-right: $margin-small;
        width: 1.5rem;

        &:before {
            color: $color--white;
        }
    }

    &__text {
        padding: $margin-smaller 0;
    }

    &__title {
        align-items: center;
        display: flex;
        font-weight: $font-weight--medium;
    }

    &__tiles {
        &.is--message {
            li {
                border-radius: 0;
                box-shadow: none;
                margin-bottom: 0;
                padding: $margin-smaller $margin-small;

                &:not(:first-of-type) {
                    border-top: solid $dimensions-border--small $color-border--grey;
                }

                &:not(:last-of-type) {
                    margin-bottom: 0;
                }
            }
        }

        li {
            align-items: center;
            background-color: $color--white;
            border-radius: $dimensions-border-radius--3;
            box-shadow: $shadow--list;
            display: flex;
            padding: $margin-small;

            &:not(:last-of-type) {
                margin-bottom: $margin-smaller;
            }

            &.is--inactive {
                border: solid $dimensions-border--default $color-border--grey-backend;
                box-shadow: none;
                opacity: .4;
            }

            &.is--active {
                background-color: $color--primary-2-dark;

                p {
                    color: $color--white;
                }
            }
        }

        &-name {
            align-items: center;
            background-color: $color--lightgrey;
            border-radius: $dimensions-border-radius--3;
            color: $color-font--mediumgrey;
            display: flex;
            font-weight: $font-weight--bold;
            justify-content: center;
            margin-right: $margin-default;
            padding: $margin-smaller;

            .is--active & {
                background-color: $color--white;
                color: $color--primary-2-dark;
            }
        }
    }
}
