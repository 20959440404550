.e-infobox {
    background-color: $color-bg--body;
    border-radius: $dimensions-border-radius--6;
    display: flex;
    max-width: $dimensions-max-width-40;
    padding: $margin-smaller;

    &.is--no-border {
        border: 0;
    }

    &.is--icon-left {
        display: flex;
    }

    &[class*='is--ico'] {
        &:before {
            align-items: center;
            border: solid $dimensions-border--small $color-font;
            border-radius: 50%;
            display: flex;
            font-size: $font-size--default;
            height: 1.5rem;
            justify-content: center;
            margin-bottom: $margin-small;
            width: 1.5rem;
        }
    }

    &.is--info-error,
    &.is--info-warning {
        *,
        &:before {
            color: $color--white;
        }

        &:before {
            border-color: $color--white;
        }
    }

    &.is--info-error {
        background-color: $color-status--error;
    }

    &.is--info-warning {
        background-color: $color-status--warning;
    }

    &.is--c-w {
        &:before {
            border: solid $dimensions-border--default $color--white;
        }
    }

    &__title {
        font-weight: $font-weight--medium;
        padding-bottom: $margin-small;
    }

    &__inner {
        .is--icon-left & {
            padding-left: $margin-smaller;
            width: calc(100% - #{2.5rem});
        }
    }

    &__text {
        .is--small & {
            font-size: $font-size--xsmall;
        }
    }

    a {
        text-decoration: underline;
    }
}
