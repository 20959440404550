/* padding top */
.is--pt-small {
    padding-top: $margin-small;
}

.is--pt-input {
    padding-top: $margin-input;
}

.is--pt-smaller {
    padding-top: $margin-smaller;
}

.is--pt-xsmaller {
    padding-top: $margin-xsmaller;
}

.is--pat,
.is--pt,
.is--pt-default {
    padding-top: $margin-default;
}

.is--pt-xdefault {
    padding-top: $margin-xdefault;
}

.is--pt-bigger {
    padding-top: $margin-bigger;
}

.is--pat-big,
.is--pt-b {
    padding-top: $margin-big;
}

.is--pat-huge {
    padding-top: $margin-huge;
}
