.c-text,
.ck-content {
    &.is--long {
        p,
        a {
            line-height: $font-lh--bigger;
        }
    }

    h3 {
        margin-bottom: $margin-smaller;
    }

    p,
    ul,
    ol {
        line-height: 1.6;
        padding-bottom: $margin-small;

        + h3 {
            margin-top: $margin-default;
        }
    }

    p {
        &:last-of-type {
            padding-bottom: 0;
        }
    }

    a {
        &[href] {
            &:not(.is--normal) {
                color: $color--sec-2;
                font-weight: $font-weight--bold;
                transition: color $transition-default;
            }

            &:hover {
                color: $color--primary-2-dark;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    ul,
    ol {
        list-style-position: inside !important;
    }

    ul {
        list-style: disc;

        &.is--icon {
            list-style: none;

            li {
                &:before {
                    padding-right: $margin-small;
                }
            }
        }
    }

    ol {
        list-style-type: decimal;

        li {
            line-height: $font-lh--default;
        }
    }

    b,
    strong {
        font-weight: $font-weight--bold;
        opacity: .9;
    }

    img {
        padding: $margin-default;
    }

    table {
        margin: $margin-default 0;

        td,
        th {
            border: solid $dimensions-border--small $color--lightgrey;
            padding: $margin-small;
            text-align: left;
        }
    }
}

.c-text {
    &.is--c-white {
        * {
            color: $color--white;
        }
    }

    &.is--8 {
        display: inline-block;
    }

    &.is--6 {
        display: inline-block;
    }

    &.is--block {
        text-align: justify;
    }


    &.is--light {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: $font-size--default;
            font-weight: $font-weight--default;
        }

        strong {
            font-weight: $font-weight--medium;
        }

        h3,
        h4,
        h5,
        h6 {
            font-size: $font-size--xdefault;
        }
    }

    #intro {
        background-color: $color-bg--body;
        border-left: solid .125rem $color--primary-3;
        border-radius: $dimensions-border-radius--3;
        display: inline-block;
        padding: $margin-smaller;
    }

    #conclusion {
        background-color: $color-bg--body;
        border-radius: $dimensions-border-radius--3;
        display: inline-block;
        padding: $margin-default;
    }

    [class*='is--special-'] {
        @include position(relative);
        display: inline-block;
        margin-bottom: $margin-small;
        padding: $margin-default $margin-default $margin-default $margin-smaller*2+$font-size--xbigger;

        &.is--special-opinion {
            @include icon(before, 'comment');
            background-color: $color--primary-4;
        }

        &.is--special-tip {
            @include icon(before, 'lightbulb');
            background-color: $color--primary-4;
        }

        &.is--special-attention {
            @include icon(before, 'attention');
            background-color: lighten($color-status--warning, 20%);
        }

        &.is--special-opinion,
        &.is--special-tip,
        &.is--special-attention {
            &:before {
                @include position(absolute, $top: 50%, $left: 0);
                color: $color-font;
                font-size: $font-size--xbigger;
                padding: $margin-smaller;
                transform: translateY(-50%);
            }
        }
    }

    blockquote {
        @include icon(before, 'quote-left');
        @include position(relative);
        align-items: center;
        background: $color-bg--body;
        border-left: .25rem solid darken($color-bg--body, 5%);
        display: flex;
        margin: $margin-small;
        padding: $margin-small;
        padding-left: 2rem;

        &:before {
            @include position(absolute, $top: 50%, $left: 0);
            color: darken($color-bg--body, 5%);
            font-size: 1em;
            padding: $margin-small;
            transform: translateY(-50%);
        }

        p {
            display: inline;
        }
    }
}
