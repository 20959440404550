/* padding left-right */
.is--plr-small {
    padding-left: $margin-small;
    padding-right: $margin-small;
}

.is--plr-input {
    padding-left: $margin-input;
    padding-right: $margin-input;
}

.is--plr-smaller {
    padding-left: $margin-smaller;
    padding-right: $margin-smaller;
}

.is--plr-xsmaller {
    padding-left: $margin-xsmaller;
    padding-right: $margin-xsmaller;
}

.is--plr,
.is--plr-d,
.is--plr-default {
    padding-left: $margin-default;
    padding-right: $margin-default;
}

.is--plr-xdefault {
    padding-left: $margin-xdefault;
    padding-right: $margin-xdefault;
}

.is--plr-bigger {
    padding-left: $margin-bigger;
    padding-right: $margin-bigger;
}

.is--plr-b,
.is--plr-big {
    padding-left: $margin-big;
    padding-right: $margin-big;
}

.is--plr-h,
.is--plr-huge {
    padding-left: $margin-huge;
    padding-right: $margin-huge;
}
