/*
 * ### general width classes
 */
.is--full-12 {
    width: 100%;
}

.is--maxwidth-350 {
    max-width: 21.875rem;
}

.is--maxwidth-600 {
    max-width: 37.5rem;
}

.is--8 {
    @include media('>medium') {
        max-width: 66.6666%;
    }
}

.is--6 {
    @include media('>medium') {
        max-width: 50%;
    }
}

.is--4 {
    @include media('>medium') {
        max-width: 33.3333%;
    }
}

.is--3 {
    @include media('>medium') {
        max-width: 25%;
    }
}
