/* margin left-right */
.is--mlr-small {
    margin: 0 $margin-small;
}

.is--mlr-input {
    margin: 0 $margin-input;
}

.is--mlr-smaller {
    margin: 0 $margin-smaller;
}

.is--mlr-xsmaller {
    margin: 0 $margin-xsmaller;
}

.is--mlr-default {
    margin: 0 $margin-default;
}

.is--mlr-xdefault {
    margin: 0 $margin-xdefault;
}

.is--mlr-bigger {
    margin: 0 $margin-bigger;
}

.is--mlr-big {
    margin: 0 $margin-big;
}

.is--mlr-huge {
    margin: 0 $margin-huge;
}
