.c-itemlist {
    > li {
        @include position(relative);
        align-items: center;
        border: solid $dimensions-border--small $color-border--grey;
        border-bottom-width: 0;
        display: flex;
        justify-content: space-between;
        padding: $margin-small;
        transition: box-shadow $transition-default, background-color $transition-default;

        &:hover {
            &:not(.is--selected) {
                &:not(.is--info) {
                    box-shadow: $shadow-backend--list-hover;
                    cursor: pointer;
                    z-index: 10;
                }
            }

            .e-btn__action {
                visibility: visible;
            }
        }

        &:last-of-type {
            border-bottom-width: $dimensions-border--small;
        }

        &.is--info {
            border: solid $dimensions-border--small $color-border--grey;
            margin-bottom: $margin-small;
        }

        &.is--selected {
            background-color: $color--primary-5;
        }

        &.is--noborder {
            border-left: solid $dimensions-border--small $color-border--grey;
        }
    }

    &__title {
        align-items: center;
        display: flex;

        span {
            align-items: center;
            display: flex;
            margin-right: $margin-small;

            &:before {
                background-color: $color-status--ok;
                border-radius: .25rem;
                content: '';
                display: block;
                height: .5rem;
                width: .5rem;
            }

            .is--not-published & {
                &:before {
                    background-color: $color-status--warning;
                }
            }

            .is--inactive & {
                &:before {
                    background-color: $color-font--backend-grey;
                }
            }
        }
    }

    &__actionmenu {
        @include position(absolute, $top: $margin-small, $right: $margin-small);
    }

    &__actions {
        border-left: solid $dimensions-border--small $color-form--lightgrey;
        margin-left: $margin-small;
        padding: $margin-small;

        .e-btn__action {
            visibility: hidden;
        }
    }

    &__container {
        &:not(:last-of-type) {
            border-bottom: solid $dimensions-border--small $color-border--grey;
            margin-bottom: $margin-small;
        }
    }

    &__content {
        width: 100%;

        &-title {
            color: $color-font--backend-grey-darker;
        }
    }
}
