.is--no-mobile {
    &-medium {
        @include media('<=medium') {
            display: none;
        }
    }
}

.is--mobile {
    &-medium {
        @include media('>medium') {
            display: none !important;
        }
    }
}
