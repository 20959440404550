// sass-lint:disable-all
// ==========================================================================
// Mixin :: Position

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    bottom: $bottom;
    left: $left;
    position: $position;
    right: $right;
    top: $top;
}
// sass-lint:enable-all
