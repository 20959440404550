.e-form__switch {
    &-checkbox {
        @include position(absolute, $top: 0, $left: 0);
        height: 0 !important;
        width: 0 !important;

        &:checked {
            + .e-form__switch-label {
                background-color: $color-status--ok !important;

                .e-form__switch-button {
                    left: calc(100% - .125rem);
                    transform: translateX(-100%);
                }
            }
        }
    }

    &-label {
        @include position(relative);
        align-items: center;
        background: $color-font--lightgrey;
        border-radius: calc($switch-dimensions-height / 2);
        cursor: pointer;
        display: flex;
        height: $switch-dimensions-height;
        justify-content: space-between;
        transition: background-color $transition-default;
        width: 4rem;

        &:active {
            .e-form__switch-button {
                width: 1.75rem;
            }
        }
    }

    &-button {
        @include position(absolute, $top: $switch-margin-tb, $left: $switch-margin-tb);
        background: $color--white;
        border-radius: #{calc($switch-dimensions-height / 2) - $switch-margin-tb};
        box-shadow: 0 0 $switch-margin-tb 0 $shadow--switch;
        content: '';
        height: #{$switch-dimensions-height - calc($switch-margin-tb * 2)};
        transition: $transition-default;
        width: #{$switch-dimensions-height - calc($switch-margin-tb * 2)};
    }
}
