.e-line {
    background-color: $color--lightgrey;
    margin-top: .4375rem;

    &,
    span {
        border-radius: .25rem;
        height: .5rem;
    }

    span {
        background-color: $color--primary-2-dark;
        display: block;
    }
}
