.c-validation {
    @include position(relative);
    padding-left: $margin-smaller;
    width: 100%;

    &__more {
        &:hover {
            cursor: pointer;
        }
    }

    &__content {
        @include position(absolute, $bottom: 0, $left: 0);
        align-items: center;
        background-color: $color--white;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        width: 100%;
    }

    &__menu {
        display: flex;

        li {
            border: solid 1px $color-font--backend-grey;
            border-radius: $dimensions-border-radius--3;
            color: $color-font--backend-grey-darker;
            font-size: $font-size--small;
            padding: $margin-smallest;
            white-space: nowrap;

            &:not(:last-of-type) {
                margin-right: $margin-small;
            }

            &:after,
            &:before {
                font-size: $font-size--xsmall;
            }

            &:after {
                margin-left: $margin-small;
            }

            &:before {
                margin-right: $margin-small;
            }
        }
    }

    &__text {
        max-width: 30rem;
        overflow: hidden;
        padding-left: $margin-smaller;
    }
}
