/*
 * ### general font classes
 */
/* font size */
.is--fs-smallest {
    font-size: $font-size--smallest;
}

.is--fs-small {
    font-size: $font-size--small;
}

.is--size-xs,
.is--fs-xs,
.is--fs-xsmall {
    font-size: $font-size--xsmall;
}

.is--fs-default {
    font-size: $font-size--default;
}

.is--fs-xdefault {
    font-size: $font-size--default;
}

.is--fs-bigger,
.is--size-bigger {
    font-size: $font-size--xdefault;
}

.is--fs-xsbigger {
    font-size: $font-size--bigger;
}

.is--fs-xxsbigger {
    font-size: $font-size--xsbigger;
}

.is--fs-xbigger {
    &,
    &:after,
    &:before {
        font-size: $font-size--xxsbigger;
    }
}

.is--fs-huge,
.is--fs-h {
    font-size: $font-size--huge-mobile;
}

.is--fs-h-x,
.is--fs-hugex {
    font-size: $font-size--hugex-mobile;
}

.is--fs-h1 {
    font-size: $dimensions-headline-mobile--h1;
}

.is--fs-h2 {
    font-size: $dimensions-headline-mobile--h2;
}

.is--fs-h3 {
    font-size: $dimensions-headline-mobile--h3;
}

.is--fs-h4 {
    font-size: $dimensions-headline-mobile--h4;
}

/* font size wide */
@include media('>xmedium') {
    .is--fs-xdefault {
        font-size: $font-size--xdefault;
    }

    .is--fs-bigger,
    .is--size-bigger {
        font-size: $font-size--bigger;
    }

    .is--fs-xsbigger {
        font-size: $font-size--xsbigger;
    }

    .is--fs-xxsbigger {
        font-size: $font-size--xxsbigger;
    }

    .is--fs-xbigger {
        &,
        &:after,
        &:before {
            font-size: $font-size--xbigger;
        }
    }

    .is--fs-huge,
    .is--fs-h {
        font-size: $font-size--huge;
    }

    .is--fs-h-x,
    .is--fs-hugex {
        font-size: $font-size--hugex;
    }

    .is--fs-h1 {
        font-size: $dimensions-headline--h1-wide;
    }

    .is--fs-h2 {
        font-size: $dimensions-headline--h2;
    }

    .is--fs-h3 {
        font-size: $dimensions-headline--h3;
    }

    .is--fs-h4 {
        font-size: $dimensions-headline--h4;
    }
}

/* font size wide */
@include media('>wide') {
    .is--fs-h1 {
        font-size: $dimensions-headline--h1;
    }
}

/* font weight */
.is--fw-lighter {
    font-weight: $font-weight--lighter;
}

.is--fw-light {
    font-weight: $font-weight--light;
}

.is--fw-de,
.is--fw-default {
    font-weight: $font-weight--default;
}

.is--fw-me,
.is--fw-medium {
    font-weight: $font-weight--medium;
}

.is--fw-bold {
    font-weight: $font-weight--bold;
}

.is--fw-boldest {
    font-weight: $font-weight--boldest;
}

.is--ok,
.is--warning,
.is--error {
    font-weight: $font-weight--bold;
}

/* line height */
.is--lh-small {
    line-height: 1;
}

/* font special */
.is--fwrap-n {
    white-space: nowrap;
}
