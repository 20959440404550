.c-infobox {
    * {
        color: $color--white;
    }

    p {
        margin: $margin-default 0;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
