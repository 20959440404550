.c-tab {
    @include scrollbar;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;

    &.is--small {
        display: none;
        overflow: hidden;
        width: 2rem;

        &.is--notempty {
            display: block;
            padding-top: $margin-smaller;

            p {
                padding-left: $margin-small;
                transform: rotate(90deg);
                transition: color $transition-default;

                &:hover {
                    color: $color--primary-3;
                    cursor: pointer;
                }
            }
        }
    }

    &__holder {
        @include position(relative);
        border: solid $dimensions-border--small $color-border--gray-darker;

        &:not(:first-of-type) {
            border-left-width: 0;
        }
    }

    &__outer {
        display: flex;
        height: 100%;

        &.is--fullwidth {
            width: 100%;
        }

        &.is--max-height {
            max-height: 45vh;
        }
    }

    &__head {
        @include position(absolute);
        background-color: $color--white;
        border-bottom: solid $dimensions-border--small $color-border--grey;
        padding: $margin-small;
        width: 100%;
        z-index: 10;

        .is--scroll & {
            backdrop-filter: blur(2px);
            background-color: $color--w-trans-8;
        }

        &-top {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        &-bottom {
            padding-top: $margin-smaller;
        }

        &-actions {
            ul {
                display: flex;

                li {
                    &:not(:last-of-type) {
                        .e-btn__action {
                            border-bottom-right-radius: 0;
                            border-right-width: 0;
                            border-top-right-radius: 0;
                        }
                    }

                    &:not(:first-of-type) {
                        .e-btn__action {
                            border-bottom-left-radius: 0;
                            border-left-width: 0;
                            border-top-left-radius: 0;
                        }
                    }
                }
            }
        }

        &-title {
            font-size: $font-size--default;
            font-weight: $font-weight--default;
        }
    }

    &__content {
        padding: $margin-small $margin-small 10rem;

        &-title {
            color: $color-font--backend-grey-darker;
            font-size: $font-size--xdefault;
            font-weight: $font-weight--default;
        }
    }
}
