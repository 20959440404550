/*
 * ### global margin config
 */

/* components */
$margin-smallest: .188rem;  // 3px
$margin-small: .375rem;     // 6px
$margin-input: .625rem;     // 10px
$margin-smaller: .875rem;   // 14px
$margin-xsmaller: 1rem;     // 16px
$margin-default: 1.25rem;   // 20px
$margin-xdefault: 1.375rem; // 22px
$margin-bigger: 1.5rem;     // 24px
$margin-big: 2.375rem;      // 38px
$margin-huge: 4.375rem;     // 70px
