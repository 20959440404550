.e-btn {
    align-items: center;
    background-color: $color--primary-3;
    border: 0;
    border-radius: $dimensions-border-radius--3;
    color: $color--white;
    display: flex;
    justify-content: center;
    min-width: 6rem;
    padding: $margin-small $margin-input;
    transition: background-color $transition-default;

    &:hover {
        background-color: $color--primary-3-darker;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .7;

        &:hover {
            background-color: $color--primary-3;
        }
    }

    &__menu {
        display: flex;

        li {
            &:not(:last-of-type) {
                margin-right: $margin-small;
            }
        }
    }

    &__no {
        background-color: transparent;

        &.is--link {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &[class*='is--ico-'] {
        align-items: center;
        display: flex;

        &:after,
        &:before {
            transition: transform $transition-default;
        }

        &:after {
            padding-left: $margin-small;
        }

        &:before {
            padding-right: $margin-small;
        }

        &:not(.is--no-effects) {
            &:not(.is--no-effects-trans) {
                &:hover {
                    &:after {
                        transform: translateX(.2rem);
                    }

                    &:before {
                        transform: translateX(-.2rem);
                    }
                }
            }
        }
    }

    &.is--orange {
        background-color: $color--white;
        border: solid $color--primary-6 $dimensions-border--small;
        color: $color--primary-6;

        &:hover {
            background-color: $color--primary-6;
            color: $color--white;
        }
    }

    &.is--light {
        background-color: $color--white;
        border: solid $dimensions-border--small $color--primary-2;
        color: $color--primary-2;

        &:hover {
            background-color: $color--primary-2;
            color: $color--white;
        }
    }

    &.is--light-ok {
        background-color: $color--white;
        border: solid $dimensions-border--small $color--primary-3;
        color: $color--primary-3;

        &:hover {
            background-color: $color--primary-3;
            color: $color--white;
        }
    }

    &.is--light-nob {
        background-color: $color--white;
        color: $color--primary-3;

        &:hover {
            background-color: $color--primary-3;
            color: $color--white;
        }
    }

    &.is--form-big {
        border-radius: 1.5rem;
        height: 3rem;
        width: 100%;
    }

    &.is--bg-reverse {
        background-color: $color--primary-2-dark;

        &:hover {
            background-color: $color--primary-2;
        }

        &:disabled {
            &:hover {
                background-color: $color--primary-2-dark;
            }
        }
    }

    &.is--bg-pri2 {
        background-color: $color--primary-2-dark;

        &:hover {
            background-color: $color--primary-2;
        }
    }

    &.is--icon {
        background-color: transparent;
        color: $color-font;
        min-width: auto;
        transition: color $transition-default;

        &:before {
            font-size: $font-size--xbigger;
        }

        &:not(.is--no-effects) {
            &:hover {
                color: $color--primary-2;
            }
        }
    }

    &.is--hover-info {
        &:before {
            color: $color-font--mediumgrey;
            font-size: $font-size--default;
        }

        &:hover {
            cursor: help;
        }
    }

    &.is--small {
        font-size: $font-size--xsmall;
    }

    &__letter {
        align-items: center;
        border: solid $dimensions-border--small $color--primary;
        border-radius: 1rem;
        display: flex;
        font-weight: $font-weight--medium;
        height: 2rem;
        justify-content: center;
        line-height: 2rem;
        text-transform: uppercase;
        transition: color $transition-default, border-color $transition-default;
        width: 2rem;

        &:hover {
            border-color: $color--primary-2;
            color: $color--primary-2;
        }

        &.is--active {
            border-color: $color--primary-3;

            &,
            * {
                color: $color--primary-3;
            }
        }
    }

    &__text {
        align-items: center;
        background-color: transparent;
        display: flex;
        padding: $margin-small;

        &:hover {
            text-decoration: underline;
        }
    }

    &__bigaction {
        align-items: center;
        background-color: transparent;
        border-radius: $dimensions-border-radius--3;
        display: flex;
        font-weight: $font-weight--light;
        justify-content: center;
        min-width: 10rem;
        padding: $margin-smaller;
        transition: background-color $transition-default;
        white-space: nowrap;

        &:hover {
            background-color: $color-form--lightgrey;
        }

        &:not(:last-of-type) {
            margin-bottom: $margin-small;
        }

        &.is--active {
            background-color: $color-form--lightgrey;
            color: $color--primary-3;
        }
    }

    &__action {
        align-items: center;
        background-color: $color-bg--body;
        border: solid $dimensions-border--small $color-border--grey-backend;
        border-radius: $dimensions-border-radius--6;
        display: flex;
        height: 2rem;
        justify-content: center;
        line-height: 100%;
        transition: background-color $transition-small;

        &:before {
            color: $color-font--button-backend;
        }

        &:disabled {
            opacity: .5;
        }

        &:empty {
            width: 2.25rem;
        }

        &:not(:empty) {
            padding: 0 $margin-small;

            &:before {
                margin-right: $margin-small;
            }
        }

        &:not([class*='is--bg-']) {
            &:not(:disabled) {
                &:hover {
                    background-color: darken($color-bg--body, 5%);
                }
            }
        }

        &[class*='is--bg-'] {
            &:before {
                color: $color--white;
            }
        }
    }

    &__cross {
        align-items: center;
        background-color: $color--white;
        border-radius: 1.25rem;
        display: flex;
        height: 2.5rem;
        justify-content: center;
        width: 2.5rem;

        &:hover {
            background-color: $color--primary;

            span {
                &:before,
                &:after {
                    background: $color--white;
                }
            }
        }

        span {
            @include position(relative);
            background: none;
            border: 0;
            display: block;
            height: 1.5rem;
            margin: 0;
            padding: 0;
            position: relative;
            width: 1.5rem;

            &:before,
            &:after {
                @include position(absolute, $right: 0, $left: 0, $top: .75rem);
                background: $color--primary;
                border-radius: .125rem;
                content: '';
                height: .125rem;
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
